import React from "react";
import useEvent from "react-use/lib/useEvent";

const get1DKeyDownDelta = (event: KeyboardEvent) => {
  const { key, code } = event;
  if (
    key === "ArrowDown" ||
    key === "ArrowRight" ||
    // Use the code property to target the physical location
    // of the WASD keys in case the user wants to use those
    // instead of the arrow keys
    code === "KeyS" ||
    code === "KeyD" ||
    // J is the Vim version of down
    // L is the Vim version of right
    code === "KeyJ" ||
    code === "KeyL"
  )
    return 1;
  if (
    key === "ArrowUp" ||
    key === "ArrowLeft" ||
    // Use the code property to target the physical location
    // of the WASD keys in case the user wants to use those
    // instead of the arrow keys
    code === "KeyW" ||
    code === "KeyA" ||
    // K is the Vim version of up
    // H is the Vim version of left
    code === "KeyK" ||
    code === "KeyH"
  )
    return -1;
  return 0;
};

interface Props {
  element: HTMLElement;
  initialSelectedIndex?: number;
}

/**
 * grep -r use1DKeyboard -- app/javascript
 * Used in:
 * * app/javascript/apps/sales/salesman_order_entry/OrderItemTable/SelectSnDialog.tsx
 * * app/javascript/parts/ConfirmDialog.tsx
 *
 * Do not use this hook unless the component with the ref is rendered
 *
 * # Parameter
 * element - an optional element to listen on. Defaults to global (i.e. window)
 * initialSelectedIndex - the index of the element to select by default
 */
const use1DKeyboard = (props: Props) => {
  const { element, initialSelectedIndex } = props;
  const elements = [];
  const selectedIndexRef = React.useRef(initialSelectedIndex || -1);

  const onKeyDown = (event: KeyboardEvent) => {
    const { length } = elements;

    // Don't handle the keyboard until the refs are initialized
    if (length < 1) return;

    const delta = get1DKeyDownDelta(event);

    // if the key wasn't one of the keys that
    // represent an arrow key, bail out
    if (!delta) return;

    // Wrap around
    // https://stackoverflow.com/questions/14785443/is-there-an-expression-using-modulo-to-do-backwards-wrap-around-reverse-overfl
    const currentIndex = selectedIndexRef.current;
    const nextIndex =
      delta === 1
        ? (currentIndex + delta) % length
        : (((currentIndex + delta) % length) + length) % length;
    selectedIndexRef.current = nextIndex;
    elements[nextIndex].focus();
  };

  useEvent("keydown", onKeyDown, element);

  const callbackRef = (index: number, element: HTMLElement) => {
    elements[index] = element;

    // I think this callback is called with null
    // when the component is un-mounted
    if (!element) return;

    if (index === initialSelectedIndex) element.focus();
  };

  return callbackRef;
};

export default use1DKeyboard;
