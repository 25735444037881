import ThemeWrapper from "@/components/ThemeWrapper";
import PropsTypes from "prop-types";
import {
  FunctionComponent,
  Key,
  createContext,
  useContext,
  useState,
} from "react";

const DialogContext = createContext(undefined);

interface DialogState<DialogProps> {
  Dialog: FunctionComponent<DialogProps>;
  props: DialogProps;
}

export function NestedDialogProvider<DialogProps extends { key: Key }>({
  children,
}) {
  const [state, setState] = useState<DialogState<DialogProps>[]>([]);

  const controller = {
    state,
    open: (dialogState: DialogState<DialogProps>) => {
      setState((prev) => [...prev, dialogState]);
    },
    close: () => {
      setState((prev) => [...prev.slice(0, prev.length - 1)]);
    },
    closeAll: () => setState([]),
    replace: (dialogState: DialogState<DialogProps>) => {
      setState((prev) => [...prev.slice(0, prev.length - 1), dialogState]);
    },
    replaceAll: (dialogStates: DialogState<DialogProps>[]) =>
      setState(dialogStates),
  };

  return (
    <DialogContext.Provider value={controller}>
      <ThemeWrapper dependant>
        {children}
        {state.map(({ Dialog, props }, index) => {
          // eslint-disable-next-line react/prop-types
          return <Dialog key={`Dialog-${index}-${Dialog.name}`} {...props} />;
        })}
      </ThemeWrapper>
    </DialogContext.Provider>
  );
}

NestedDialogProvider.propTypes = {
  children: PropsTypes.node,
};

export const useNestedDialogContext = () => useContext(DialogContext);
