import PropsTypes from "prop-types";
import { createContext, useContext } from "react";

import usePromiseConfirm, { PromiseConfirm } from "../hooks/usePromiseConfirm";
import ConfirmDialog from "../parts/ConfirmDialog";

const ConfirmDialogContext = createContext<PromiseConfirm | undefined>(
  undefined
);

export const ConfirmDialogProvider = ({ children }) => {
  const { confirmProps, promiseConfirm } = usePromiseConfirm();

  return (
    <ConfirmDialogContext.Provider value={promiseConfirm}>
      {children}
      <ConfirmDialog {...confirmProps} />
    </ConfirmDialogContext.Provider>
  );
};

ConfirmDialogProvider.propTypes = {
  children: PropsTypes.node,
};

export const usePromiseConfirmContext = () => useContext(ConfirmDialogContext);
