import RailsRanger from "rails-ranger";

const config = {
  axios: {
    baseURL: "/",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    maxBodyLength: 500000000,
  },
};

export default new RailsRanger(config);
