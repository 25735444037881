// Inspired by https://github.com/seznam/compose-react-refs
// The above lib didn't work, so I made a simple hook
// that fulfills the same purpose

type Ref = React.MutableRefObject<HTMLElement> | React.RefCallback<HTMLElement>;

/**
 * @deprecated This doesn't need to be a hook
 * use helpers/composeRefs instead
 * Pass all refs as arguments to populate them all
 * with the html element
 */
const useComposeRefs =
  (...refs: Ref[]) =>
  (element: HTMLElement) => {
    refs.forEach((ref) => {
      if (typeof ref === "undefined") return;
      if (typeof ref === "function") ref(element);
      else if (typeof ref === "object") ref.current = element;
      else throw "unhandled ref type: " + typeof ref;
    });
  };

export default useComposeRefs;
