import { useState } from "react";

/**
 * Used in:
 *  apps/fulfillment/shipping_system/ShipTicket
 *  apps/sales/salesman_order_entry/CompleteDialog/CdFooter
 *  apps/sales/salesman_order_entry/OrderItemTable/useUStockOnHandZero.ts
 *  apps/procurement/create_po/Index.tsx
 *  apps/stock/serial_stock/index.tsx
 */

interface PromiseConfirmProps {
  title?: any;
  content?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

export type PromiseConfirm = (props: PromiseConfirmProps) => Promise<boolean>;

const usePromiseConfirm = () => {
  const [confirmProps, setConfirmProps] = useState<object>({
    open: false,
    handleClose: () => setConfirmProps((old) => ({ ...old, open: false })),
    handleAffirmative: undefined,
    title: "",
    content: "",
  });
  const promiseConfirm = (props: PromiseConfirmProps): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const handleAction = (yesNo: boolean) => {
        setConfirmProps((prev) => ({ ...prev, open: false }));
        resolve(yesNo);
      };

      setConfirmProps({
        ...props,
        open: true,
        handleClose: () => handleAction(false),
        handleAffirmative: () => handleAction(true),
      });
    });
  };
  return { confirmProps, promiseConfirm };
};

export default usePromiseConfirm;
