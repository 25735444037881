import CircularProgress from "@mui/material/CircularProgress";
import "./LoadingBarrier.css";

type Props = {
  message?: string;
  ellipses?: boolean;
  loading: boolean;
  backgroundColor?: string;
  blur?: boolean;
};

export const LoadingBarrier = ({
  message = "Loading",
  ellipses = true,
  loading,
  backgroundColor = "rgba(255,255,255,0.1)",
  blur = false,
}: Props) => {
  if (!loading) return null;
  return (
    <div
      className={"loading-barrier"}
      style={{
        backgroundColor: backgroundColor,
        ...(blur ? { backdropFilter: "blur(0.1rem)" } : {}),
      }}
    >
      <CircularProgress />
      <div>
        {message}
        {ellipses ? "..." : ""}
      </div>
    </div>
  );
};
